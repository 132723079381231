import React from 'react';
import { createIcon } from "@chakra-ui/react";


const Dwg = createIcon({
    viewBox: '0 0 58 58',
    displayName: 'Dwg',
    path: [
        <svg version="1.1" id="Capa_1" viewBox="0 0 58 58">
        <g>
            <path d="M18.493,46.282c-0.287-0.333-0.677-0.602-1.169-0.807s-1.13-0.308-1.914-0.308h-0.957v7.629h1.627
                c1.112,0,1.914-0.355,2.406-1.066s0.738-1.741,0.738-3.09c0-0.419-0.05-0.834-0.15-1.244C18.974,46.986,18.78,46.615,18.493,46.282
                z" fill='currentColor' />
            <path d="M51.5,39V13.978c0-0.766-0.092-1.333-0.55-1.792L39.313,0.55C38.964,0.201,38.48,0,37.985,0H8.963
                C7.777,0,6.5,0.916,6.5,2.926V39H51.5z M37.5,3.391c0-0.458,0.553-0.687,0.877-0.363l10.095,10.095
                C48.796,13.447,48.567,14,48.109,14H37.5V3.391z M11.5,13h17v12h2v-4h4v-6h2v6h4v4h6v2h-6v4h-4v6h-2v-6h-4v-4h-2v3.065h-17V13z" fill='currentColor' />
            <path d="M6.5,41v15c0,1.009,1.22,2,2.463,2h40.074c1.243,0,2.463-0.991,2.463-2V41H6.5z M20.681,50.808
                c-0.178,0.588-0.403,1.08-0.677,1.477s-0.581,0.709-0.923,0.937s-0.672,0.398-0.991,0.513c-0.319,0.114-0.611,0.187-0.875,0.219
                C16.95,53.984,16.754,54,16.627,54h-3.814V43.924h3.035c0.848,0,1.593,0.135,2.235,0.403s1.176,0.627,1.6,1.073
                s0.74,0.955,0.95,1.524c0.209,0.569,0.314,1.155,0.314,1.757C20.947,49.511,20.858,50.22,20.681,50.808z M33.279,54.055h-2.133
                l-1.654-7.602l-1.764,7.602h-2.146l-2.557-10.131h1.859l1.777,8.695l1.914-8.695h1.859l1.777,8.695l1.928-8.695H36L33.279,54.055z
                M45.57,52.701c-0.21,0.265-0.444,0.48-0.704,0.649s-0.533,0.308-0.82,0.417S43.463,53.954,43.157,54s-0.608,0.068-0.909,0.068
                c-0.602,0-1.155-0.109-1.661-0.328s-0.948-0.542-1.326-0.971c-0.378-0.429-0.675-0.966-0.889-1.613
                c-0.214-0.647-0.321-1.395-0.321-2.242s0.107-1.593,0.321-2.235c0.214-0.643,0.51-1.178,0.889-1.606
                c0.378-0.429,0.822-0.754,1.333-0.978s1.062-0.335,1.654-0.335c0.547,0,1.058,0.091,1.531,0.273s0.897,0.456,1.271,0.82
                l-1.135,1.012c-0.219-0.265-0.47-0.456-0.752-0.574s-0.574-0.178-0.875-0.178c-0.337,0-0.658,0.063-0.964,0.191
                s-0.579,0.344-0.82,0.649s-0.431,0.699-0.567,1.183c-0.137,0.483-0.21,1.075-0.219,1.777c0.009,0.684,0.08,1.276,0.212,1.777
                c0.132,0.501,0.314,0.911,0.547,1.23s0.497,0.556,0.793,0.711s0.608,0.232,0.937,0.232c0.101,0,0.234-0.007,0.403-0.021
                s0.337-0.036,0.506-0.068s0.33-0.075,0.485-0.13s0.269-0.132,0.342-0.232v-2.488h-1.709v-1.121h3.336V52.701z" fill='currentColor' />
        </g>
        </svg>
    ]
})

export default Dwg;
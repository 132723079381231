import { Box, Flex, Heading, Image, Link, Table, TableContainer, Text } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import video from '../assets/IMG_2191.mp4';
import { useParams } from 'react-router-dom';
import { cards } from '../mockedData';
import Features from '../components/Features2';
import FixedBg from '../components/FixedBg';
import Footer from '../components/Footer';
import HowToInstall from '../components/HowToInstall';
import { useTranslation } from 'react-i18next';
import Pdf from '../theme/icons/Pdf';
import EFL3DsMax from '../theme/icons/EFL3DsMax';
import Dwg from '../theme/icons/Dwg';
import Dxf from '../theme/icons/Dxf';

const Product = () => {
    const { id } = useParams();
    const [ showCard, setShowCard ] = useState()
    const {t, i18n} = useTranslation('common');

    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, [id]);

    useEffect(() => {
        window.scrollTo({
            top: 900,
            behavior: "smooth",
          });
    }, [])
    const scrollToDown = () => {
        window.scrollTo({
            top: 900,
            behavior: "smooth",
          });
    }
    useMemo(() => {
        cards?.map((card) => (
            card?.title === id ? setShowCard(card) : null
        ))
    }, [id])
    return (
        <>
            <Box position="relative">
                <Box
                    as="video"
                    objectFit='cover'
                    height={{base: '650px', sm: '650px', md: '800px', xl: '100vh'}}
                    loop
                    width="100%"
                    playsInline
                    webkitPlaysinline
                    autoPlay src={video}/>
                <Box
                    position="absolute"
                    width="100%"
                    height={{base: '650px', sm: '650px', md: '800px', xl: '100vh'}}
                    bgColor='rgba(255,255,255,.1)' top="0">
                    <Header t={t} i18n={i18n} />
                    <Hero scrollTo={scrollToDown} title={showCard?.title} />
                </Box>
            </Box>
            <Flex
                bgColor="blackAlpha.900"
                py="150px"
                mb={{ base: '100px', md: '150px' }}
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap">
                <Box width={{base: "100%", md: "50%"}}>
                    <Text
                        color="whiteAlpha.700"
                        fontSize={{ base: '5xl', md: '5xl' }}
                        align="center"
                    >{showCard?.title}</Text>
                    <Image
                        mx={{ base: '0' }}
                        width={{ base: '100%', md: '90%' }}
                        src={showCard?.imgUrl} />
                </Box>
                <Box >
                    <TableContainer color="whiteAlpha.700">
                        <Box>
                            <Text
                                fontSize={{base: "md", md: "3xl"}}
                                px={{ base: 4, md: 0}}
                                mb="15px"
                            >{t('techTitles.1')}</Text>
                        </Box>
                        <Table whiteSpace="normal" colorScheme='white'>
                            <Box maxW={{base: '375px', md: "unset"}} fontSize={{base: 14, md: 20}} as='tbody'>
                                <Box
                                    as="tr"
                                    borderBottomWidth="1px"
                                    borderBottomColor="gray.600"
                                    px={{base: '0'}}
                                    css={{
                                        td: {
                                            padding: '7px 16px'
                                        }
                                    }}>
                                    <Box as="td">{t('technicaltitle.0')}</Box>
                                    <Box as="td">{t(`${showCard?.translate}.0`)}</Box>
                                </Box>
                                <Box
                                    as="tr"
                                    borderBottomWidth="1px"
                                    borderBottomColor="gray.600"
                                    px={{base: '0'}}
                                    css={{
                                        td: {
                                            padding: '7px 16px'
                                        }
                                    }}>
                                    <Box as="td">{t('technicaltitle.1')}</Box>
                                    <Box as="td">{t(`${showCard?.translate}.1`)}</Box>
                                </Box>
                                <Box
                                    as="tr"
                                    borderBottomWidth="1px"
                                    borderBottomColor="gray.600"
                                    px={{base: '0'}}
                                    css={{
                                        td: {
                                            padding: '7px 16px'
                                        }
                                    }}>
                                    <Box as="td">{t('technicaltitle.2')}</Box>
                                    <Box as="td">{t(`${showCard?.translate}.2`)}</Box>
                                </Box>
                                <Box
                                    as="tr"
                                    borderBottomWidth="1px"
                                    borderBottomColor="gray.600"
                                    px={{base: '0'}}
                                    css={{
                                        td: {
                                            padding: '7px 16px'
                                        }
                                    }}>
                                    <Box as="td">{t('technicaltitle.3')}</Box>
                                    <Box as="td">{t(`${showCard?.translate}.3`)}</Box>
                                </Box>
                                <Box
                                    as="tr"
                                    borderBottomWidth="1px"
                                    borderBottomColor="gray.600"
                                    px={{base: '0'}}
                                    css={{
                                        td: {
                                            padding: '7px 16px'
                                        }
                                    }}>
                                    <Box as="td">{t('technicaltitle.4')}</Box>
                                    <Box as="td">{t(`${showCard?.translate}.4`)}</Box>
                                </Box>
                                <Box
                                    as="tr"
                                    borderBottomWidth="1px"
                                    borderBottomColor="gray.600"
                                    px={{base: '0'}}
                                    css={{
                                        td: {
                                            padding: '7px 16px'
                                        }
                                    }}>
                                    <Box as="td">{t('technicaltitle.5')}</Box>
                                    <Box as="td">{t(`${showCard?.translate}.5`)}</Box>
                                </Box>
                                <Box
                                    as="tr"
                                    borderBottomWidth="1px"
                                    borderBottomColor="gray.600"
                                    px={{base: '0'}}
                                    css={{
                                        td: {
                                            padding: '7px 16px'
                                        }
                                    }}>
                                    <Box as="td">{t('technicaltitle.6')}</Box>
                                    <Box as="td">{t(`${showCard?.translate}.6`)}</Box>
                                </Box>
                                <Box
                                    as="tr"
                                    borderBottomWidth="1px"
                                    borderBottomColor="gray.600"
                                    px={{base: '0'}}
                                    css={{
                                        td: {
                                            padding: '7px 16px'
                                        }
                                    }}>
                                    <Box as="td">{t('technicaltitle.7')} </Box>
                                    <Box as="td">{t(`${showCard?.translate}.7`)}</Box>
                                </Box>
                                <Box
                                    as="tr"
                                    borderBottomWidth="1px"
                                    borderBottomColor="gray.600"
                                    px={{base: '0'}}
                                    css={{
                                        td: {
                                            padding: '7px 16px'
                                        }
                                    }}>
                                    <Box as="td">{t('technicaltitle.8')}</Box>
                                    <Box as="td">{t(`${showCard?.translate}.8`)}</Box>
                                </Box>
                                <Box
                                    as="tr"
                                    borderBottomWidth="1px"
                                    borderBottomColor="gray.600"
                                    px={{base: '0'}}
                                    css={{
                                        td: {
                                            padding: '7px 16px'
                                        }
                                    }}>
                                    <Box as="td">{t('technicaltitle.9')}</Box>
                                    <Box as="td">{t(`${showCard?.translate}.9`)}</Box>
                                </Box>
                                <Box
                                    as="tr"
                                    borderBottomWidth="1px"
                                    borderBottomColor="gray.600"
                                    px={{base: '0'}}
                                    css={{
                                        td: {
                                            padding: '7px 16px'
                                        }
                                    }}>
                                    <Box as="td">{t('technicaltitle.10')}</Box>
                                    <Box as="td">{t(`${showCard?.translate}.10`)}</Box>
                                </Box>
                                <Box
                                    as="tr"
                                    borderBottomWidth="1px"
                                    borderBottomColor="gray.600"
                                    px={{base: '0'}}
                                    css={{
                                        td: {
                                            padding: '7px 16px'
                                        }
                                    }}>
                                </Box>
                            </Box>
                        </Table>
                        
                        <Text
                            fontSize={{ base: 'md', md: '3xl' }}
                            mb="10px"
                            px={{ base: 4, md: 0}}
                            pb="10px"
                            borderBottomWidth="1px"
                            mt="70px">{t('techTitles.0')}</Text>
                        <Box
                            borderBottomWidth="1px"
                            pb="30px"
                            height="50px"
                            transition='all 0.2s ease-in-out'
                            _hover={{
                                height: '130px',
                                "div" : {
                                    opacity: '1',
                                    display: 'block',
                                }
                            }}
                        >
                            <Text
                                fontSize={{ base: 'md', md: '2xl' }}
                                px={{ base: 4, md: 0}}
                            >{t('techTitles.4')}</Text>
                            <Flex
                                p="10px 5px"
                                transition='all 0.2s ease-in-out'
                                opacity="0"
                                display="none">
                                    {/* <Link href="#"> */}
                                        <Pdf fontSize="60px" color="white"/>
                                    {/* </Link> */}
                            </Flex>
                        </Box>
                        <Box
                            borderBottomWidth="1px"
                            mt="10px"
                            height="50px"
                            transition='all 0.2s ease-in-out'
                            _hover={{
                                height: '130px',
                                "div" : {
                                    opacity: '1',
                                    display: 'block',
                                }
                            }}
                        >
                            <Text
                                fontSize={{ base: 'md', md: '2xl' }}
                                px={{ base: 4, md: 0}}
                            >{t('techTitles.2')}</Text>
                            <Flex
                                p="10px 5px"
                                position="relative"
                                transition='all 0.2s ease-in-out'
                                opacity="0"
                                display="none">
                                    {/* <Link href="#"> */}
                                        <Pdf fontSize="60px" color="white"/>
                                    {/* </Link> */}
                            </Flex>
                        </Box>
                        <Box
                            mt="10px"
                            height="50px"
                            borderBottomWidth="1px"
                            transition='all 0.2s ease-in-out'
                            _hover={{
                                height: '130px',
                                "div" : {
                                    opacity: '1',
                                    display: 'flex',
                                }
                            }}
                        >
                            <Text
                                fontSize={{ base: 'md', md: '2xl' }}
                                px={{ base: 4, md: 0}}
                            >{t('techTitles.3')}</Text>
                            <Flex
                                p="10px 5px"
                                transition='all 0.2s ease-in-out'
                                opacity="0"
                                display="none">
                                <Box>
                                    <Link href={showCard?.cad?.smaxZip} download>
                                        <EFL3DsMax color="white" fontSize="60px" />
                                    </Link>
                                </Box>
                                <Box mx="25px">
                                    <Link href={showCard?.cad?.dwg} download>
                                        <Dwg color="white" fontSize="60px" />
                                    </Link>
                                </Box>
                                <Box>
                                    <Link href={showCard?.cad?.dxf} download>
                                        <Dxf color="white" fontSize="60px" />
                                    </Link>
                                </Box>
                            </Flex>
                        </Box>
                    </TableContainer>
                </Box>
            </Flex>
            <Box
                maxW={{ base: "unset", md: "1400px" }}
                mx={{base: "10px", md: "auto"}}
                marginY={{ base: "70px", md: "250px"}}
            >
                    <Heading fontSize="6xl" mb="20px" as="h2">{t(`welcome.subtitle`)}</Heading>
                    <Text>{t(`welcome.description`)}</Text>
            </Box>
            <FixedBg />
            <Features t={t} />
            <HowToInstall t={t} />
            <Footer t={t} />
        </>
    )
}

export default Product;
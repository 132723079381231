import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
// import Header from "../components/Header";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
    })
    return (
        <>
            <Heading as="h1" fontSize="3xl" textAlign="center" mt="100px">Privacy & Policy</Heading>
            <Box
                m={{ base: "0 20px", md: "50px auto" }}
                maxW={700}
                >
                <Text mt={6}>This is the privacy policy for the Erri Fire app. The Erri Fire app uses Bluetooth Low Energy to connect to electric fireplaces and act as a remote control. The app does not collect any personal data from users.</Text>
                <h2>Information Collection</h2>
                <Text mt={6}>The Erri Fire app does not collect any personal information, such as names, email addresses, or browsing data. As the app solely relies on Bluetooth Low Energy for fireplace control, it does not utilize any tracking technologies or cookies.</Text>

                <h2>Data Usage</h2>
                <Text mt={6}>Since the Erri Fire app does not collect any user data, it does not use or share any personal information.</Text>

                <h2>Data Security</h2>
                <Text mt={6}>We prioritize the security and privacy of our users. Although the Erri Fire app does not store or transmit personal data, we take appropriate measures to ensure the security of the app and the information transmitted via Bluetooth Low Energy.</Text>

                <h2>Third-Party Links</h2>
                <Text mt={6}>The Erri Fire app does not contain Third-Party Links.</Text>

                <h2>Changes to this Privacy Policy</h2>
                <Text mt={6}>The privacy policy for the Erri Fire app is subject to change without prior notice. Any updates or modifications will be reflected on this page.</Text>

                <h2>Contact Us</h2>
                <Text mt={6}>If you have any questions or concerns regarding the privacy policy or the Erri Fire app, please visit our contact page at <Link to="/contact" >https://errifire.com/contact</Link>.</Text>

                <Text mt={6}>This privacy policy was last updated on June 03, 2023.</Text>
            </Box>
        </>
    )
}
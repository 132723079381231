import React from "react";
import { Box, Flex, Heading, Image, Text, Link } from "@chakra-ui/react";
import smartphoneMockup from '../../assets/phone.jpeg'
import appstore from '../../assets/appstore.png'
import googleplay from '../../assets/googleplay.png'

export default function Features({t}){
    return(
        <Flex
            flexWrap="wrap"
            justifyContent="flex-end"
            mt={{ base: "95px", md: "150px"}}
            alignItems={{base: 'center', md: 'flex-start'}}
        >
            <Box
                px={{base: 4, md: 0}}
                mt={{base: "0px", md: "120px"}}
            >
                <Heading mb={{base: "20px", md: "50px"}} fontSize={{ base: "4xl", md: "5xl"}} as="h2">{t('control.title')}</Heading> 
                <Text maxW={600}>
                {t('control.description')}
                </Text>
                <Flex flexWrap={{base:"wrap", md: "nowrap"}} my="40px">
                <Link
                    href="https://apps.apple.com/am/app/erri-fire/id6449534691">
                    <Image
                        height={{ base: "55px", md: "60px"}}
                        transition={'all .3s ease'}
                        _hover={{
                            transform: 'scale(1.05)',
                        }}
                        mr={{ base: "10px", md: "25px"}} src={appstore} />
                </Link>
                <Link href="https://play.google.com/store/apps/details?id=com.ardeco.errifire" >
                    <Image
                        height={{ base: "55px", md: "60px"}}
                        transition={'all .3s ease'}
                        _hover={{
                            transform: 'scale(1.05)',
                        }}
                        src={googleplay} />
                </Link>
            </Flex>
            </Box>
            <Box>
                <Image
                    w={850}
                    src={smartphoneMockup}
                />
            </Box>
        </Flex>
    )
}
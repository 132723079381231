import { Box } from "@chakra-ui/react"
import React from "react"
import background from '../../assets/background.jpg'

export default function FixedBg() {
    return (
        <>
            <Box
                width="full"
                height={{base: '400px', md: '400px'}}
                backgroundPosition="center"
                backgroundSize="cover"
                backgroundAttachment={{base: "", md: "fixed"}}
                bgRepeat="no-repeat"
                bgImage={`url(${background})`}
            />
        </>
    )
}
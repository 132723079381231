import { Box } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Header from '../components/Header';
import ContactPart from '../components/ContactPart';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const {t, i18n} = useTranslation('common');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
    })
    return (
       <Box>
            <Box position="relative">
                <Box height={{base: '1100px', sm: '650px', md: '1000px',}} bgColor="blackAlpha.100" width="100%" />
                <Box
                    position="absolute"
                    width="100%"
                    bgColor='rgba(0,0,0,.9)'
                    playsInline
                    webkitPlaysinline
                    height={{base: '580px', sm: '650px'}}
                    top="0">
                    <Header t={t} i18n={i18n} />
                    <ContactPart t={t} />
                </Box>
            </Box>
            <Footer t={t} />
       </Box>
    )
}

export default Contact;
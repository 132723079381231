import {
  Box,
  Container,
  Stack,
  Text,
  useColorModeValue,
  Button,
  Grid,
  GridItem,
  Flex,
} from '@chakra-ui/react';

import InstagramIcon from '../../theme/icons/Instagram.jsx';
import FacebookIcon from '../../theme/icons/FacebookIcon.jsx';
import LogoIcon from '../../theme/icons/Logo.jsx';
import { Link, Link as NavLink } from 'react-router-dom'
import ErriLink from '../ErriLink/index.jsx';
import { LinkItems, cards } from '../../mockedData/index.js';
import YoutubeIcon from '../../theme/icons/YoutubeI.jsx';

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

const SocialButton = ({
  children,
  label,
  href,
}) => {
  
  return (
    <Button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      target='_blank'
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
        {label}
      {children}
    </Button>
  );
};

export default function Footer({t}) {
  return (
    <Box
      bg={useColorModeValue('#0f0f0f', '#282827')}
      color={useColorModeValue('white', 'white')}>
      <Container as={Grid}  maxW={'8xl'} py={12}>
        <Link to="/"><LogoIcon fontSize="120px" mb={10} /></Link>
        <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)'}} columns={{ base: 1, sm: 2, md: 8 }} gap={{ base: '50px', md: '100px', lg: '200px'}}>
          <GridItem colSpan={1} align={'flex-start'}>
            <ListHeader>{t(`menu.company`)}</ListHeader>
            <Flex
              flexDir="column"
            >
              {
                LinkItems?.map((link, index) => (
                  <ErriLink to={link.to}>
                    {t(`menu.${link.name}`)}
                  </ErriLink>
                ))
              }
            </Flex>
          </GridItem>

          <GridItem align={'flex-start'}>
            <ListHeader>{t(`menu.products`)}</ListHeader>
            <Flex
              flexDir="column"
            >
              {
                cards?.map((card, index) => (
                  <ErriLink key={`fireplace-${index}`} to={card.title}>
                    {card.title}
                  </ErriLink>
                ))
              }
            </Flex>
          </GridItem>

          <GridItem align={'flex-start'}>
            <ListHeader>{t(`menu.contact`)}</ListHeader>
            <Flex
              flexDir="column"
            >
                <Text>Yerevan, Armenia</Text>
                <Text>+375-93434305</Text>
                <Text>info@errifire.com</Text>
            </Flex>
          </GridItem>
          <GridItem align={'flex-start'}>
          <ListHeader>{t(`legal`)}</ListHeader>
          <NavLink to="/privacy-policy">
            <ListHeader>{t(`privacy`)}</ListHeader>
          </NavLink>
          </GridItem>
        </Grid>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={'solid'}
        borderColor="gray.700"
        >
        <Container
          as={Stack}
          maxW='6xl'
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ md: 'space-between' }}
          align={{ md: 'center' }}>
          <Text>© 2016 Erri Fire. All rights reserved</Text>
          <Stack direction={'row'} maxW="2xl" spacing={6}>
            <SocialButton href="https://www.facebook.com/people/Erri-Fireplaces/100063587050379/">
            <Box
              transition="all 0.2s ease-in-out"
              _hover={{
                transform: 'scale(1.2)'
              }}
              borderRadius="3xl"
              p="2px 4px">
              <FacebookIcon
                color="white"
                fontSize="19px" />
              </Box>
            </SocialButton>
            <SocialButton href="https://www.instagram.com/erri_fireplaces/?igshid=MzRlODBiNWFlZA%3D%3D">
              <Box
                transition="all 0.2s ease-in-out"
                _hover={{
                  transform: 'scale(1.2)'
                }}
                borderRadius="3xl"
                p="3px 4px">
                <InstagramIcon fontSize="19px" />
              </Box>
            </SocialButton>
            <SocialButton href="https://www.youtube.com/channel/UCd52W4PFmDNGJZReOXhvkuw">
              <Box
                transition="all 0.2s ease-in-out"
                _hover={{
                  transform: 'scale(1.2)'
                }}
                p="3px 4px"
                borderRadius="3xl">
                <YoutubeIcon fontSize="25px" />
              </Box>
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
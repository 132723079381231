import React from 'react';
import { createIcon } from "@chakra-ui/react";


const Pdf = createIcon({
    viewBox: '0 0 58 58',
    displayName: 'Pdf',
    path: [
        <svg fill="currentColor" version="1.1" id="Capa_1" viewBox="0 0 58 58" >
        <g>
            <path d="M39.57,25.259c0.149-0.209,0.207-0.343,0.23-0.415c-0.123-0.065-0.286-0.197-1.175-0.197c-0.505,0-1.139,0.022-1.811,0.108
                c0.815,0.627,1.014,0.944,1.547,0.944C38.594,25.7,39.262,25.69,39.57,25.259z"/>
            <path d="M20.536,32.634c0.149-0.047,1.019-0.444,2.64-3.436c-2.138,1.201-3.008,2.188-3.07,2.744
                C20.095,32.034,20.068,32.276,20.536,32.634z"/>
            <path d="M51.5,39V13.978c0-0.766-0.092-1.333-0.55-1.792L39.313,0.55C38.964,0.201,38.48,0,37.985,0H8.963
                C7.777,0,6.5,0.916,6.5,2.926V39H51.5z M37.5,3.391c0-0.458,0.553-0.687,0.877-0.363l10.095,10.095
                C48.796,13.447,48.567,14,48.109,14H37.5V3.391z M18.432,31.755c0.182-1.628,2.195-3.332,5.985-5.068
                c1.504-3.296,2.935-7.357,3.788-10.75c-0.998-2.172-1.968-4.99-1.261-6.643c0.248-0.579,0.557-1.023,1.134-1.215
                c0.228-0.076,0.804-0.172,1.016-0.172c0.504,0,0.947,0.649,1.261,1.049c0.295,0.376,0.964,1.173-0.373,6.802
                c1.348,2.784,3.258,5.62,5.088,7.562c1.311-0.237,2.439-0.358,3.358-0.358c1.566,0,2.515,0.365,2.902,1.117
                c0.32,0.622,0.189,1.349-0.39,2.16c-0.557,0.779-1.325,1.191-2.22,1.191c-1.216,0-2.632-0.768-4.211-2.285
                c-2.837,0.593-6.151,1.651-8.828,2.822c-0.836,1.774-1.637,3.203-2.383,4.251c-1.023,1.438-1.907,2.107-2.782,2.107h0
                c-0.348,0-0.682-0.113-0.967-0.326C18.506,33.216,18.366,32.347,18.432,31.755z"/>
            <path d="M21.222,45.858c-0.142-0.196-0.34-0.36-0.595-0.492c-0.255-0.132-0.593-0.198-1.012-0.198h-1.23v3.992h1.504
                c0.2,0,0.398-0.034,0.595-0.103c0.196-0.068,0.376-0.18,0.54-0.335c0.164-0.155,0.296-0.371,0.396-0.649
                c0.1-0.278,0.15-0.622,0.15-1.032c0-0.164-0.023-0.354-0.068-0.567C21.456,46.26,21.363,46.055,21.222,45.858z"/>
            <path d="M29.343,18.174c-0.716,2.474-1.659,5.145-2.674,7.564c2.09-0.811,4.362-1.519,6.496-2.02
                C31.815,22.15,30.466,20.192,29.343,18.174z"/>
            <path d="M6.5,41v15c0,1.009,1.22,2,2.463,2h40.074c1.243,0,2.463-0.991,2.463-2V41H6.5z M22.896,48.429
                c-0.173,0.415-0.415,0.764-0.725,1.046c-0.31,0.282-0.684,0.501-1.121,0.656s-0.921,0.232-1.449,0.232h-1.217V54h-1.641V43.924
                h2.898c0.428,0,0.852,0.068,1.271,0.205c0.419,0.137,0.795,0.342,1.128,0.615c0.333,0.273,0.602,0.604,0.807,0.991
                s0.308,0.822,0.308,1.306C23.156,47.552,23.069,48.014,22.896,48.429z M32.952,50.808c-0.178,0.588-0.403,1.08-0.677,1.477
                s-0.581,0.709-0.923,0.937s-0.672,0.398-0.991,0.513c-0.319,0.114-0.611,0.187-0.875,0.219C29.222,53.984,29.026,54,28.898,54
                h-3.814V43.924h3.035c0.848,0,1.593,0.135,2.235,0.403s1.176,0.627,1.6,1.073s0.74,0.955,0.95,1.524
                c0.209,0.569,0.314,1.155,0.314,1.757C33.219,49.511,33.13,50.22,32.952,50.808z M41.9,45.168h-4.635v3.172h4.211v1.121h-4.211V54
                h-1.668V43.924H41.9V45.168z"/>
            <path d="M30.765,46.282c-0.287-0.333-0.677-0.602-1.169-0.807s-1.13-0.308-1.914-0.308h-0.957v7.629h1.627
                c1.112,0,1.914-0.355,2.406-1.066s0.738-1.741,0.738-3.09c0-0.419-0.05-0.834-0.15-1.244
                C31.245,46.986,31.052,46.615,30.765,46.282z"/>
            <path d="M28.736,9.712c-0.098,0.033-1.33,1.757,0.096,3.216C29.781,10.813,28.779,9.698,28.736,9.712z"/>
        </g>
        </svg>
    ]
})

export default Pdf;
import React from 'react';
import { createIcon } from "@chakra-ui/react";


const EFL3DsMax = createIcon({
    viewBox: '0 0 1000 1000',
    displayName: 'EFL3DsMax',
    path: [
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
        <g><g><g>
            <path fill='currentColor' d="M497.2,732.4c-12,0-19.8,1.1-24.4,2.1v156c4.6,1,12,1,18.7,1c48.8,0.4,80.7-26.5,80.7-83.5C572.5,758.6,543.5,732.4,497.2,732.4z"/>
            <path fill='currentColor' d="M855.3,244.8c-0.1-4.5-1.5-8.9-4.6-12.4l-189-215.8c0-0.1-0.1-0.1-0.1-0.1c-1.1-1.3-2.4-2.3-3.8-3.2c-0.4-0.3-0.8-0.5-1.3-0.8c-1.2-0.7-2.5-1.2-3.8-1.6c-0.4-0.1-0.7-0.2-1-0.3c-1.4-0.3-2.9-0.5-4.4-0.5H182.9c-21.2,0-38.4,17.2-38.4,38.4v903.1c0,21.2,17.2,38.4,38.4,38.4h634.1c21.2,0,38.4-17.2,38.4-38.4V247C855.5,246.3,855.4,245.6,855.3,244.8z M182.9,48.4h445.2v196.6c0,10.6,8.6,19.2,19.2,19.2h169.7l0,398.3H182.9V48.4z M279.9,942.6c-31.6,0-58.3-8.3-72.6-17.3l11.7-42.9c10.2,5.3,33.5,15,56.8,15c29.7,0,44.7-14.3,44.7-32.7c0-24.1-24.1-35-49.3-35h-23.3v-41h22.2c19.2-0.4,43.6-7.5,43.6-28.2c0-14.7-12-25.6-36.1-25.6c-19.9,0-41,8.6-51.1,14.7l-11.7-41.4c14.7-9.4,44-18.4,75.6-18.4c52.3,0,81.2,27.5,81.2,60.9c0,26-14.7,46.3-44.8,56.8v0.8c29.3,5.3,53,27.5,53,59.4C379.9,911,341.9,942.6,279.9,942.6z M589.9,902.9c-25.1,20.9-63.3,30.8-110,30.8c-27.9,0-47.8-1.8-61.2-3.5V696c19.8-3.2,45.6-4.9,72.9-4.9c45.3,0,74.6,8.1,97.6,25.5c24.8,18.4,40.3,47.8,40.3,89.9C629.5,852,612.9,883.5,589.9,902.9z M723.2,934.8c-27.2,0-54.1-7.1-67.6-14.5l11-44.6c14.5,7.4,36.8,14.9,59.8,14.9c24.8,0,37.8-10.3,37.8-25.8c0-14.9-11.3-23.4-40-33.6c-39.6-13.8-65.5-35.7-65.5-70.4c0-40.7,34-71.8,90.2-71.8c26.9,0,46.7,5.7,60.8,12l-12,43.5c-9.6-4.6-26.5-11.3-49.9-11.3c-23.4,0-34.7,10.6-34.7,23c0,15.2,13.4,21.9,44.2,33.6c42.1,15.6,61.9,37.5,61.9,71.1C819.5,900.8,788.7,934.8,723.2,934.8z"/></g><g>
            <path fill='currentColor' d="M304,327.5c-0.2,0-0.4,0.1-0.5,0.1l1,1C304.4,328.2,304.2,327.8,304,327.5z"/>
            <path fill='currentColor' d="M656.2,210.1c-49.8,38.6-101.8,27.4-155.6,24.8c0,0,0.2,0.1,0.3,0.2c-5.2-0.3-10.4-0.4-15.6-0.2c-53.8,2.6-105.8,13.7-155.6-24.8c-26.3-20.4-61.6,3.1-57.3,35.9c3.7,28.1,20.4,54.4,31.7,81.5c0,0,0.1,0,0.1,0c-8.3-25-19.6-49.5-23.2-75.1c-1.6-11.7,13.6-37,19.3-36.5c20.2,1.9,40.9,10.3,58.3,21.1c4.4,2.7-0.6,24.4-4.8,36.1c-5.8,16-13.2,32-23.4,45.4c-4.7,6.1-17,6.4-26.2,9.1c0.1,0.4,0.3,0.8,0.4,1.2c0,0-1.1-1-1.1-1h0l0,0c3.3,20.8,6.4,41.7,10,62.5c2.2,13,4.1,26.2,7.9,38.8c6.6,22,17,43.1,21.5,65.5c6,30.1,17.7,52.5,48.9,62.9c17.3,5.8,32.2,19.1,49.6,24.2c21.9,6.4,48.9,17,67.1,9.6c57.5-23.4,130.9-33.8,130.7-121.8c0-12.2,11.3-24.1,15.6-36.7c4.3-12.6,7.8-25.1,9.5-37.5c5.3-15,15.9-54,18-67.3l-1.1,1c0.2-0.4,0.3-0.8,0.4-1.2c-9.2-2.7-21.5-2.9-26.2-9.1c-10.2-13.4-17.6-29.4-23.4-45.4c-4.2-11.7-9.2-33.4-4.8-36.1c17.5-10.8,38.1-19.1,58.3-21.1c5.7-0.5,20.9,24.8,19.3,36.5c-3.6,25.6-14.9,50.2-23.2,75.1c0,0,0.1,0,0.1,0c11.3-27.1,28-53.4,31.7-81.5C717.7,213.2,682.5,189.7,656.2,210.1z M481.6,262.4c4.7,0,9.4,0,14.1,0c0,23.7,0,47.4,0,71.1c-4.7,0-9.4,0-14.1,0C481.6,309.8,481.6,286.1,481.6,262.4z M428.9,422.8c-15.9,33.6-25.4,66.2-12,102.7c1.3,3.6-1.6,8.8-4.7,23.4c-45.7-61.3-7.7-109.6,8-161.8c-26.3-10.2-50.7-19.7-79.2-30.9c4-24.6,27.9-34.6,55.7-35.4c28.4-0.8,43,17.8,42.3,45C438.5,385,436.9,405.9,428.9,422.8z M525.1,549.2c-27.2,0-45.1,0-67.2,0c24-25.6,17.1-45-7.4-62.8c-6.4-4.7-12.7-17.5-10.6-23.9c2.3-7.1,13.6-14.1,22-15.7c14.3-2.7,29.9-3.5,44.1-0.8c12,2.3,22.8,10.7,34.1,16.5c-6.1,9.6-10.9,20.3-18.5,28.5C505,508.7,494.6,525.7,525.1,549.2z M556.6,386.5c12.3,51.3,56.3,100.6,4.8,152.2c-0.3-6.7-2.8-14.2-0.5-19.8c12.8-31.6,2.6-59.4-10.3-88.3c-7.8-17.4-12.9-37.3-13.8-56.3c-1.2-25.7,5.5-51.4,37.6-53.3c29.9-1.8,54.1,9.5,61.6,35.4C606.8,367.6,583.2,376.5,556.6,386.5z"/><polygon points="682.3,327.6 682.3,327.6 682.2,327.6 "/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></g>
        </svg>
    ]
})

export default EFL3DsMax;
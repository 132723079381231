import EFL60 from '../assets/products/EFL060.png'
import EFL90 from '../assets/products/EFL090.png'
import EFL120 from '../assets/products/EFL120.png'
import EFL150 from '../assets/products/EFL150.png'
import EFL060DSMAX from '../assets/products/EFL060CADfiles/EFL0603dsMax.rar'
import EFL060DWG from '../assets/products/EFL060CADfiles/EFL060.dwg'
import EFL060DXF from '../assets/products/EFL060CADfiles/EFL060.dxf'
import EFL090DSMAX from '../assets/products/EFL090CADfiles/EFL0903dsMax.rar'
import EFL090DWG from '../assets/products/EFL090CADfiles/EFL090.dwg'
import EFL090DXF from '../assets/products/EFL090CADfiles/EFL090.dxf'
import EFL120DSMAX from '../assets/products/EFL120CADfiles/EFL1203dsMax.rar'
import EFL120DWG from '../assets/products/EFL120CADfiles/EFL120.dwg'
import EFL120DXF from '../assets/products/EFL120CADfiles/EFL120.dxf'
import EFL150DSMAX from '../assets/products/EFL150CADfiles/EFL1503dsMax.rar'
import EFL150DWG from '../assets/products/EFL150CADfiles/EFL150.dwg'
import EFL150DXF from '../assets/products/EFL150CADfiles/EFL150.dxf'

export const cards = [
    {
        title: 'EFL060',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting',
        imgUrl: EFL60,
        translate: 'technicalERF060',
        cad: {
            smaxZip: EFL060DSMAX,
            dwg: EFL060DWG,
            dxf: EFL060DXF
        }
    },
    {
        title: 'EFL090',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting',
        imgUrl: EFL90,
        translate: 'technicalERF090',
        cad: {
            smaxZip: EFL090DSMAX,
            dwg: EFL090DWG,
            dxf: EFL090DXF
        }
    },
    {
        title: 'EFL120',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting',
        imgUrl: EFL120,
        translate: 'technicalERF120',
        cad: {
            smaxZip: EFL120DSMAX,
            dwg: EFL120DWG,
            dxf: EFL120DXF
        }
    },
    {
        title: 'EFL150',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting',
        imgUrl: EFL150,
        translate: 'technicalERF150',
        cad: {
            smaxZip: EFL150DSMAX,
            dwg: EFL150DWG,
            dxf: EFL150DXF
        }
    },
]

export const LinkItems = [
    { name: 'products', to: '/products' },
    { name: 'about', to: '/about' },
    { name: 'contact', to: '/contact' },
  ];
import { Box, CloseButton, Flex, Link, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import LogoIcon from "../../theme/icons/Logo";
import NavItem from "./NavItem";
import FacebookIcon from "../../theme/icons/FacebookIcon";
import InstagramIcon from "../../theme/icons/Instagram";
import { Link as RouterLink, useLocation } from "react-router-dom";
import YoutubeIcon from "../../theme/icons/YoutubeI";

const SidebarContent = ({ linkItems, i18n, t, onClose, posStatic, ...rest}) => {
  const [isHomePage, setIsHomePage] = useState(false);

  const location = useLocation();
    useEffect(() => {
      setIsHomePage(location.pathname === '/' );
    }, [location.pathname]);
    return (
      <Box
        position={posStatic}
        w='100%'
        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
        {...rest}>
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          h="20" alignItems="center" mx={{ xl: "28"}} justifyContent="space-between">
          <CloseButton display={{ base: 'block', md: 'none' }} pos="absolute" right="10px" top="10px" color="white" onClick={onClose} />
          <Flex
            flexDir={{ base: 'column', md: 'row' }}
            alignItems="center">
              {
                isHomePage ? (
                  <LogoIcon fontSize="120px"/>
                ): (
                  <RouterLink  to="/"><LogoIcon fontSize="120px"/></RouterLink>
                )
              }
            <Flex
              flexDir={{ base: 'column', md: 'row' }}
              ml={{ base: 0, md: 20}}>
              {linkItems?.map((link) => (
                  <NavItem exact="true" key={link?.name} _hover={{ color: 'whiteAlpha.900' }} link={link?.to} icon={link?.icon}>
                      {t(`menu.${link?.name}`)}
                  </NavItem>
              ))}
            </Flex>
          </Flex>
          <Flex alignItems="center">
            <Flex mr={20} color="white">
              <Text
                _hover={{
                  color: 'orange.300'
                }}
                cursor="pointer"
                onClick={() => i18n.changeLanguage('en')} mr={2}>
                EN</Text>
              <Text
                  _hover={{
                    color: 'orange.300'
                  }}
                  cursor="pointer"
                  onClick={() => i18n.changeLanguage('ru')} >
                RU</Text>
            </Flex>
            <Flex
              alignItems="center"
            >
              <Link target="_blank" href="https://www.facebook.com/people/Erri-Fireplaces/100063587050379/" mr={6}>
                <Box
                  transition="all 0.2s ease-in-out"
                  
                  borderRadius="3xl"
                  p="2px 4px">
                  <FacebookIcon
                    color="white"
                    _hover={{
                      transform: 'scale(1.2)',
                  }}
                    fontSize="19px" />
                </Box>
              </Link>
              <Link target="_blank" href="https://www.instagram.com/erri_fireplaces/?igshid=MzRlODBiNWFlZA%3D%3D" mr={6}>
                <Box
                    transition="all 0.2s ease-in-out"
                    
                    borderRadius="3xl"
                    p="2px 4px">
                    <InstagramIcon
                      color="white"
                      _hover={{
                        transform: 'scale(1.2)',
                    }}
                      fontSize="19px" />
                  </Box>
              </Link>
              <Link target="_blank" href="https://www.youtube.com/channel/UCd52W4PFmDNGJZReOXhvkuw">
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    transition="all 0.2s ease-in-out"
                    
                    borderRadius="3xl"
                    p="2px 4px">
                    <YoutubeIcon
                      fontSize="25px"
                      color="white"
                      _hover={{
                        transform: 'scale(1.2)',
                    }}
                    />

                  </Flex>
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    );
  };

  export default SidebarContent;
  
  SidebarContent.defaultProps = {
    // posStatic: 'absolute',
  }
import { Box, Heading, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import video from '../assets/IMG_2191.mp4';
import { cards } from '../mockedData';
import FirePlaceCard from '../components/Card';
import FixedBg from '../components/FixedBg';
import { Link, useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import HowToInstall from '../components/HowToInstall';
import { useTranslation } from 'react-i18next';
import Features2 from '../components/Features2';

const Products = () => {
    const {id} = useParams();
    const {t, i18n} = useTranslation('common');
    const scrollToDown = () => {
        window.scrollTo({
            top: 700,
            behavior: "smooth",
          });
    }
    useEffect(() => {
        scrollToDown()
    }, [id]);
    return (
        <>
            <Box position="relative">
                <Box
                    as="video"
                    objectFit='cover'
                    height={{base: '650px', sm: '650px', md: '800px', xl: '100vh'}}
                    loop
                    muted
                    width="100%"
                    autoPlay
                    playsInline
                    webkitPlaysinline
                    src={video}/>
                <Box position="absolute" width="100%" bgColor='rgba(255,255,255,.1)' height={{base: '650px', sm: '650px', md: '800px', xl: '100vh'}} top="0">
                    <Header t={t} i18n={i18n} />
                    <Hero t={t} scrollTo={scrollToDown} title={t('pagesTitle.products')} />
                </Box>
            </Box>


            <Box
                display={{ base: "inline-block", sm: "flex" }}
                w="100%"
                px={{base: 4, md: 0}}
                alignItems="flex-start"
                justifyContent={{base: 'center', md: 'space-around'}}
                flexWrap={{base: 'wrap', xl: 'nowrap'}}
                mx={{base: 0, md: 'auto'}}
                my={{base: '100px', md: '150px'}}
                id="products">
                {
                    cards?.map((card, index) => (
                        <Link
                            to={`/products/${card.title}`}
                            key={`products-page-card-${index}`}>
                            <FirePlaceCard
                                seeMore={t('product.button')}
                                card={card}
                                size={{base: '100%', sm: "100%", md: 'md', xl: '380px'}}
                                transition="all 0.2s ease-in-out"
                                cursor="pointer"
                                _hover={{
                                    transform: 'scale(1.02)',
                                    '.see-more': {
                                        width: '250px',
                                    }
                                }}
                                mt={{base: 4, xl: 0}}
                            />
                        </Link>
                    ))
                }
            </Box>
            <Box
                px={{base: 4, md: 0}}
                maxW={{ base: "unset", md: "1400px" }}
                mx="auto"
                marginY={{ base: "70px", md: "250px"}}
            >
                    <Heading fontSize="6xl" mb="20px" as="h2">{t(`welcome.subtitle`)}</Heading>
                    <Text>{t(`welcome.description`)}</Text>
            </Box>
            <FixedBg />
            <Features2 t={t} />
            <HowToInstall t={t}/>
            <Footer t={t} />
        </>
    )
}

export default Products;
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Box, ChakraProvider } from '@chakra-ui/react'
import Home from "./pages/Home.jsx";
import About from "./pages/About.jsx";
import Products from "./pages/Products.jsx";
import Contact from "./pages/Contact.jsx";
import Admin from "./pages/Admin.jsx";
import Product from "./pages/Product.jsx";
import NotFound from "./pages/404.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx";
import './index.css';

function App() {
  return (
    <div className="App">
        <Router>
          <ChakraProvider>
            <Box>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/about" element={< About/>} />
                <Route path="/products" element={<Products/>} />
                <Route path="/products/:id" element={<Product/>} />
                <Route path="/contact" element={<Contact/>} />
                <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
                <Route path="/admin" element={<Admin/>} />
                {/* <Route path="/404" element={<NotFound/>} /> */}
                {/* <Route path="*" element={<Navigate to="/404" />} /> */}
              </Routes>
            </Box>
          </ChakraProvider>
        </Router>
    </div>
  );
}

export default App;

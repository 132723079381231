import React from 'react';
import { Box, Step, StepDescription, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, useSteps } from "@chakra-ui/react"

const steps = [
    { title: 'title', description: 'description' },
    { title: 'title', description: 'description' },
  ]
  
  export default function Example({t}) {
    const { activeStep } = useSteps({
      index: 2,
      count: steps.length,
    })
    let index = 0
    return (
      <Stepper index={activeStep} colorScheme='yellow' orientation='vertical' height='200px' gap='0'>
        {steps?.map((step) => {
          index++
          return (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<StepNumber />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
    
              <Box>
                <StepTitle fontSize="24px">{t(`install.steps.step${index}.${step.title}`)}</StepTitle>
                <StepDescription>{t(`install.steps.step${index}.${step.description}`)}</StepDescription>
              </Box>
    
              <StepSeparator />
            </Step>
          )
        }
        )}
      </Stepper>
    )
  }
import { Box, Heading, Text } from '@chakra-ui/react';
import React from 'react';

export default function WelcomePart({t}) {
    return (
        <>
            <Box
                px={{base: 4, md: '0'}}
                maxW="900px"
                m="120px auto">
                <Heading as="h4" size="xl"  my="4">{t('welcome.subtitle')}</Heading>
                <Text my={8}>
                {t('welcome.description')}
                </Text>
            </Box>
        </>
    )
}
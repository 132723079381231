import React from "react"
import { Box, Flex, Heading, Text, Link as LinkErri } from "@chakra-ui/react"
import background from '../../assets/background.jpg'

export default function TextImageBlock({title, seemore, imageUrl, withoutSeeMore, marginY, description, textOrder, imageOrder}){
    return (
        <>
            <Flex
                padding={{base: '0 20px', md: '0 15px', lg: '0 30px'}}
                flexWrap={{base: 'wrap', md: 'wrap', lg: 'nowrap'}}
                justifyContent="space-between"
                alignItems="center"
                my={{base: 10, md: marginY}}
            >
                <Box
                    maxW={600}
                    ml={{ base: "0", md: "20px" }}
                    order={textOrder}
                >
                    <Heading
                        as="h2"
                        fontSize="5xl"
                        mb={4}
                    >{title}</Heading>
                    <Text>
                        {description}
                     </Text>
                     {
                        !withoutSeeMore && (
                            <LinkErri
                                href="/about"
                                color="orange.300">
                                    {seemore}
                            </LinkErri>
                        )
                     }
                </Box>
                <Box
                    order={imageOrder}
                    my={{base: 8, md: 0}}
                    w={{base: 'full', sm: 'full', md: 'full', lg: 600, xl: 800}}
                    h={{base: 200, sm: 400, md: 550}}
                    backgroundImage={`url(${imageUrl})`}
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                />
            </Flex>
        </>
    )
}

TextImageBlock.defaultProps = {
    title: 'Our Projects',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
    marginY: 140,
    imageUrl: background,
}
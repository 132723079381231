import {
    Box,
    Button,
    Flex,
    Heading,
    Icon,
    Text
} from "@chakra-ui/react";
import React from "react"
import { Link } from "react-router-dom";
import ArrowDown from "../../theme/icons/ArrowDown";
import "./style.css"

function Hero({title, t, scrollTo, margin, color}) {
    return (
        <Box
            position="relative"
            bgPosition="center"
            bgRepeat="no-repeat"
            bgSize="cover"
            p={6}
        >
            {
                title ? (
                    <Flex
                        margin={margin || { base: '100px auto', xl: '20% auto'}}
                        justifyContent="center"
                        flexDir="column"
                    >
                        <Heading
                            textAlign="center"
                            mb="6"
                            color={color}
                        >{title}</Heading>
                        <Flex
                            cursor="pointer"
                            onClick={scrollTo}
                            w="full"
                            className="scroll-down"
                            href="#">
                            <Icon fontSize="50px" color={color} as={ArrowDown} className="arrow bounce" />
                        </Flex>
                    </Flex>
                ) : (
                     <Box
                        margin={{ base: '0 10px', xl: '0 10%'}}
                        maxW="54rem"
                        pt={{ base: '0.5rem',  md: '100px', lg: '200px', xl: '15%' }}>
                            <Heading as="h2" fontSize={{ base: '2.5rem', lg: '4rem' }} mb="6" color="#fff">
                                {t('hero.heading1')}<Text as="span" color="orange.300">{t('hero.colorful')}</Text>{t('hero.heading2')}
                            </Heading>
                            <Box
                                justify="start"
                                flexDirection={{ base: 'column', lg: 'row' }}
                                align-items="center"
                                maxWidth="42rem"
                                marginX={{ base: 'auto', lg: '0' }}
                            >
                            <Text mb="10" color="#fff">
                                {t('hero.description')}
                            </Text>
                            <Button
                                width={{ base: '100%', lg: '50%' }}
                                as={Link}
                                to="/products"
                                bg="#fff"
                                color="#000000"
                                fontWeight="bold"
                                px="2.5rem"
                                py="1.8rem"
                                rounded="md"
                                _hover={{ bg: 'gray.300', textDecoration: 'none' }}
                            >
                                {t('hero.button')}
                            </Button>
                        </Box>
                    </Box> 
                )
            }
      
    </Box>
    )
}

export default Hero;

Hero.defaultProps = {
    color: "whiteAlpha.800"
}
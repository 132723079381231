import { Text } from '@chakra-ui/react';
import React from 'react';

export default function ErriLink({children, to, ...props}) {
    const openProduct = (link) => {
        window.location.href = window.location.pathname.includes('products') ? link : `products/${link}`
      }
    return (
        <Text cursor="pointer" onClick={() => openProduct(to)}>
            {children}
        </Text>
    )
}
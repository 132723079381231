import { Box, Heading, Link as LinkErri, Text } from "@chakra-ui/react";
import React from "react";
import { Carousel } from 'react-carousel3';
import FirePlaceCard from "../Card";
import { Link } from "react-router-dom";
  
export default function Carousel3D({cards, t}) {

    const carouselId = document.querySelector('#carouselId');
    if(carouselId){
        carouselId.addEventListener('mouseenter', (e) => {
            e.preventDefault();
        })
    }
    return (
        <Box
            px={{base: 4, md: 0}}
            height={{base: 'auto', md: '100%', xl: '770px'}}
            overflow="hidden"
            mb={{base: 0, md: 20}}
        >
            <Box
                m={{base: '20px auto', md: '100px auto'}}
                style={{
                    marginTop: '100px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    maxWidth: '1350px',
                }}
            >
                <Box>
                    <Heading
                        as="h2"
                        fontSize="5xl"
                        mb={6}
                    >
                        {t('product.title')}
                    </Heading>
                    <Text
                        maxW={500}
                        mr={8}>
                        {t('product.description')}
                    </Text>
                    <LinkErri
                        color="orange.300"
                        href="/products">
                            {t('product.button')}
                    </LinkErri>
                </Box>
                <Box
                    mt={{ base: 8, md: 0 }}
                    width="100%"
                    overflowX="auto"
                    display={{base: 'flex', md: 'none'}}
                >
                    {
                        cards?.map((card, index) => <Link to={`products/${card.title}`} key={`card-${index}`}>
                                <FirePlaceCard
                                    _hover={{
                                        '.see-more': {
                                            width: '130px',
                                        }
                                    }}
                                    sizeW={{base: "300px", md: "xs"}}
                                    card={card}/>
                            </Link>)
                    }
                </Box>
                <Box
                    id="carouselId"
                    display={{base: 'none', md: 'none', xl: 'block'}}
                >
                    <Carousel width={617} height={370} xOrigin={307} xRadius={407} delay={5000} autoPlay={true}>
                        {
                            cards?.map((card, index) => 
                                <Link to={`products/${card.title}`} key={`card-${index}`}>
                                    <FirePlaceCard
                                        seeMore={t('product.button')}
                                        _hover={{
                                            '.see-more': {
                                                width: '130px',
                                            }
                                        }}
                                        size={{base: 'none', md: 'sm'}}
                                        card={card} />
                                </Link>)
                        }
                    </Carousel>
                </Box>
            </Box>
        </Box>
    )
}
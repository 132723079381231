import { Flex, Heading, Image, Link } from "@chakra-ui/react";
import React from "react";
import Example from "../Stepper";
import appstore from '../../assets/appstore.png'
import googleplay from '../../assets/googleplay.png'


export default function HowToInstall({t}) {
    return (
        <Flex
            flexDirection="column"
            margin={{base: "150px 4px", md: "150px auto"}}
            maxW="500px"
            px={{base: '20px', md: '0'}}
        >
            <Heading textAlign={{ md: "center" }} fontSize={{ base: "4xl", md: "5xl"}} as="h2">
                {t('install.title')}
            </Heading>
            <Flex flexWrap={{base:"wrap", md: "nowrap"}} my="40px">
                <Link
                    href="https://apps.apple.com/am/app/erri-fire/id6449534691">
                    <Image
                        height={{ base: "50px", md: "60px"}}
                        transition={'all .3s ease'}
                        _hover={{
                            transform: 'scale(1.05)',
                        }}
                        mr={{ base: "10px", md: "25px"}} src={appstore} />
                </Link>
                <Link href="https://play.google.com/store/apps/details?id=com.ardeco.errifire" >
                    <Image
                        height={{ base: "50px", md: "60px"}}
                        transition={'all .3s ease'}
                        _hover={{
                            transform: 'scale(1.05)',
                        }}
                        src={googleplay} />
                </Link>
            </Flex>
            <Example t={t} />
        </Flex>
    );
}
import React from "react";
import {
    Box,
    Flex,
    Heading,
    Image,
    Text,
    Link
} from "@chakra-ui/react";
import smartphoneMockup from '../../assets/phone.jpeg'
import appstore from '../../assets/appstore.png'
import googleplay from '../../assets/googleplay.png'
import fireplace from '../../assets/t1.png'

export default function Features2({t}){
    return(
        <Flex
            flexWrap={{base: "wrap", sm: "wrap", md: "wrap", lg: "nowrap" }}
            justifyContent="flex-end"
            mt="150"
            alignItems={{base: 'center', md: 'flex-end'}}
        >
            <Box
                px={{base: 4, md: 6}}
                mt={{base: "0px", md: "90px"}}
                position="relative"
            >
                <Heading 
                    mb={{base: "20px", md: "50px"}}
                    fontSize={{ base: "4xl", md: "5xl"}}
                    as="h2">{t('control.title')}</Heading> 
                <Text maxW={670}>
                {t('control.description')}
                </Text>
                <Flex flexWrap={{base:"wrap", md: "nowrap"}} my="40px">
              
                <Link
                    href="https://apps.apple.com/am/app/erri-fire/id6449534691">
                    <Image
                        height={{ base: "55px", md: "60px"}}
                        transition={'all .3s ease'}
                        _hover={{
                            transform: 'scale(1.05)',
                        }}
                        mr={{ base: "10px", md: "25px"}} src={appstore} />
                </Link>
                <Link href="https://play.google.com/store/apps/details?id=com.ardeco.errifire" >
                    <Image
                        height={{ base: "55px", md: "60px"}}
                        transition={'all .3s ease'}
                        _hover={{
                            transform: 'scale(1.05)',
                        }}
                        src={googleplay} />
                </Link>
            </Flex>
            <Image w="650px" zIndex="-1" position="relative" src={fireplace}/>
            </Box>
            <Box>
                <Image
                    ml="0px"
                    w={750}
                    src={smartphoneMockup}
                />
            </Box>
        </Flex>
    )
}
import React, { useLayoutEffect, useRef } from 'react';
import {
  IconButton,
  Flex,
  Drawer,
  DrawerContent,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import {
  FiMenu,
} from 'react-icons/fi';
import SidebarContent from './SideBarContent';
import './style.css'
import { Link } from 'react-router-dom';
import LogoIcon from '../../theme/icons/Logo';
import { LinkItems } from '../../mockedData';



export default function SimpleSidebar({children, t, i18n, bgColor, position}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [scroll, setScroll] = useState(false);
  const emptyHeaderRef = useRef(null);
  useLayoutEffect(()=>{
    window.addEventListener("scroll", () => {
        // setScroll(window.scrollY > 5);
      });
  }, [])
  return (
    <>
      <Box ref={emptyHeaderRef} />
      <SidebarContent
        i18n={i18n}
        t={t}
        backgroundColor={bgColor}
        zIndex="1000"
        transition="all .5s ease"
        // className={scroll && 'fixedTop'}
        width="100%"
        linkItems={LinkItems}
        onClose={() => onClose}
        display={{ base: 'none', lg: 'block' }}
        borderBottomColor="rgba(255,255,255,.2)"
        borderBottomWidth={{base: 'none', md: '1px'}}
        py={4}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
          <DrawerContent
            bgColor={{base: 'black', md: bgColor}}>
            <SidebarContent
              t={t}
              i18n={i18n}
              posStatic={position}
              linkItems={LinkItems}
              onClose={onClose} />
          </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: 'flex', lg: 'none' }} onOpen={onOpen} />
      {children}
    </>
  );
}



const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, lg: 60 }}
      px={{ base: 10, md: 5 }}
      alignItems="center"
      justifyContent="space-between"
      {...rest}>

      <Link to="/"><LogoIcon fontSize="100px"/></Link>
      <IconButton
        variant="none"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu color="white" fontSize={24} />}
      />
    </Flex>
  );
};


SimpleSidebar.defaultProps = {
  bgColor: 'none',
}
import { Flex } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavItem = ({ link, icon, children, ...rest }) => {
  const location = useLocation();
    return (
      <Link to={link} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
        <Flex
          align="center"
          px="4"
          py="45px"
          mx="4"
          borderBottomWidth={location.pathname === link ? '2px' : '0px'}
          borderBottomColor="orange.300"
          cursor="pointer"
          color="whiteAlpha.700"
          transition="all .3s ease"
          _hover={{ color: 'whiteAlpha.700' }}
          {...rest}>
          {children}
        </Flex>
      </Link>
    );
  };

  export default NavItem;
import React, { useEffect } from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero'
import Footer from '../components/Footer';
import { Box } from '@chakra-ui/react';
import video from '../assets/IMG_2191.mp4'
import Carousel3D from '../components/Carousel';
import FixedBg from '../components/FixedBg';
import WelcomePart from '../components/WelcomePart';
import Features from '../components/Features';
import TextImageBlock from '../components/TextImageBlock';
import { cards } from '../mockedData';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import company from '../assets/companyy.jpg'


const Home = () => {
    const {id} = useParams();
    const {t, i18n} = useTranslation('common');
    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, [id]);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
    })
    return (
        <>
            <Box position="relative">
                <Box
                    as="video"
                    objectFit='cover'
                    height={{base: '650px', sm: '650px', md: '800px', xl: '100vh'}}
                    loop
                    muted
                    width="100%"
                    playsInline
                    webkitPlaysinline
                    autoPlay
                    src={video}/>
                <Box position="absolute" width="100%" bgColor='rgba(255,255,255,.1)' height={{base: '650px', sm: '650px', md: '800px', xl: '100vh'}} top="0">
                    <Header t={t} i18n={i18n} />
                    <Hero t={t} />
                </Box>
            </Box>
            <WelcomePart t={t}/>
            <FixedBg bgImage="../../assets/smartmockup.jpg" />
            <Box
                maxW={{ base: "unset", md: "1600px" }}
                mx="auto"
                mt={{ base: "120px", md: "150px" }}
            >
                <TextImageBlock
                    textOrder={{base: "0", md: "1"}}
                    seemore={t('product.button')}
                    title={t('about.title')}
                    description={t('about.description')}
                    imageUrl={company}
                />
            </Box>
            <Carousel3D t={t} cards={cards} />
            <FixedBg bgImage="../../assets/smartmockup.jpg" />
            <Features t={t} />
            <Footer t={t} />
        </>
    )
}

export default Home;
import React, { useState } from "react"
import { Box, Button, Container, Flex, FormControl, FormLabel, HStack, Heading, IconButton, Input, InputGroup, InputLeftElement, Link, Text, Textarea, VStack, Wrap, WrapItem, useToast } from "@chakra-ui/react"
import {  BsPerson, BsInstagram, BsYoutube } from 'react-icons/bs'
import emailjs from 'emailjs-com';

import {
    MdPhone,
    MdEmail,
    MdLocationOn,
    MdFacebook,
    MdOutlineEmail,
  } from 'react-icons/md';

export default function ContactPart({t}) {
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [file, setFile] = useState(null);

    const maxFileSize = file?.size > 4096000;
    const toast = useToast();
    const handleNameChange = (event) => {
      setName(event.target.value);
    };
  
    const handleSurnameChange = (event) => {
      setMessage(event.target.value);
    };
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
  
    const handleFileChange = (event) => {
      setFile(event.target.files[0]);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      const templateParams = {
        name: name,
        message: message,
        email: email,
      };


      if (file) {
        const reader = new FileReader();
        toast({
          title: 'Message sended successfully!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
          reader.onload = () => {
          const attachment = {
            name: file.name,
            data: reader.result, // Extract the base64 content from the data URL
          };
          templateParams['attachment'] = attachment.data;
  
          emailjs
          .send('service_mv56vwe', 'template_d6hu9xl', templateParams, 'eUk_OhJYtd3TZ2ji5')
          .then((result) => {
            console.log(result.text);
            // Handle successful email sending
          })
          .catch((error) => {
            console.log(error.text);
            // Handle error in email sending
          });
        };
  
        reader.readAsDataURL(file);
      } else {
        // Send the email using Email.js
        toast({
          title: 'Message sended without file!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        emailjs
          .send('service_mv56vwe', 'template_d6hu9xl', templateParams, 'eUk_OhJYtd3TZ2ji5')
          .then((result) => {
            console.log(result.text);
            // Handle successful email sending
          })
          .catch((error) => {
            console.log(error.text);
            // Handle error in email sending
          });
           // Read the file as data URL
          };
        setName('');
        setMessage('');
        setEmail('');
        setFile('');
  
     
    };
    return (
        <Container maxW="full" mt={{ base: "65px", md: 5, lg: "120px" }} centerContent overflow="hidden">
            <Flex>
                <Box
                bg="gray.800"
                color="white"
                borderRadius="lg"
                p={{ sm: 5, md: 5, lg: 16 }}>
                <Box p={4}>
                    <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
                    <WrapItem>
                        <Box>
                        <Heading>{t(`contacts`)}</Heading>
                        <Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.500">
                            {t(`contact.info`)}
                        </Text>
                        <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                            <VStack pl={0} spacing={3} alignItems="flex-start">
                            <Button
                                size="md"
                                height="48px"
                                width="200px"
                                variant="ghost"
                                color="#DCE2FF"
                                _hover={{ border: '2px solid #1C6FEB' }}
                                leftIcon={<MdPhone color="#1970F1" size="20px" />}>
                                    <Link _hover={{ textDecoration: 'none'}} href="tel:+37493434305">  
                                        +374 93434305
                                    </Link>
                            </Button>
                            <Button
                                size="md"
                                height="48px"
                                width="200px"
                                variant="ghost"
                                color="#DCE2FF"
                                _hover={{ border: '2px solid #1C6FEB' }}
                                leftIcon={<MdEmail color="#1970F1" size="20px" />}>
                                    <Link _hover={{ textDecoration: 'none'}} href="mailto:info@errifire.com">
                                        info@errifire.com
                                    </Link>
                            </Button>
                            <Button
                                size="md"
                                height="48px"
                                width="200px"
                                variant="ghost"
                                color="#DCE2FF"
                                _hover={{ border: '2px solid #1C6FEB' }}
                                leftIcon={<MdLocationOn color="#1970F1" size="20px" />}>
                                {t(`location`)}
                            </Button>
                            </VStack>
                        </Box>
                        <HStack
                            mt={{ lg: 10, md: 10 }}
                            spacing={5}
                            px={5}
                            alignItems="flex-start">
                                <Link target="_blank" href="https://www.facebook.com/people/Erri-Fireplaces/100063587050379/">
                                    <IconButton
                                        aria-label="facebook"
                                        variant="ghost"
                                        size="lg"
                                        isRound={true}
                                        _hover={{ bg: '#0D74FF' }}
                                        icon={<MdFacebook size="28px" />}
                                    />
                                </Link>
                                <Link target="_blank" href="https://www.instagram.com/erri_fireplaces/?igshid=MzRlODBiNWFlZA%3D%3D">
                                    <IconButton
                                        aria-label="Instagram"
                                        variant="ghost"
                                        size="lg"
                                        isRound={true}
                                        _hover={{ bg: '#0D74FF' }}
                                        icon={<BsInstagram size="28px" />}
                                    />
                                </Link>
                                <Link target="_blank" href="https://www.youtube.com/channel/UCd52W4PFmDNGJZReOXhvkuw">
                                    <IconButton
                                        aria-label="discord"
                                        variant="ghost"
                                        size="lg"
                                        isRound={true}
                                        _hover={{ bg: '#0D74FF' }}
                                        icon={<BsYoutube size="28px" />}
                                    />
                                </Link>
                        </HStack>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box bg="white" borderRadius="lg">
                        <Box m={8} color="#0B0E3F">
                            <VStack spacing={5}>
                                <form method="post" onSubmit={handleSubmit}>
                                    <FormControl isRequired id="name">
                                        <FormLabel>{t(`contact.name`)}</FormLabel>
                                        <InputGroup borderColor="#E0E1E7">
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<BsPerson color="gray.800" />}
                                        />
                                        <Input name="name" value={name} placeholder="Enter your name" type="text" onChange={handleNameChange} size="md" />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl isRequired id="name">
                                        <FormLabel>{t(`contact.email`)}</FormLabel>
                                        <InputGroup borderColor="#E0E1E7">
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<MdOutlineEmail color="gray.800" />}
                                        />
                                        <Input name="email" value={email} type="email" onChange={handleEmailChange} placeholder="Enter your email" size="md" />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl isRequired id="name">
                                        <FormLabel>{t('contact.message')}</FormLabel>
                                        <Textarea
                                            type="text"
                                            value={message}
                                            onChange={handleSurnameChange}
                                            borderColor="gray.300"
                                            _hover={{
                                                borderRadius: 'gray.300',
                                            }}
                                            name="message"
                                            placeholder="Enter your message"
                                            maxLength={500}
                                            maxH="200px"
                                        />
                                        <Input
                                            accept=".jpg, .png, .jpeg, .gif, pdf"
                                            onChange={handleFileChange}
                                            mt={{base: 4, md: 4}}
                                            type="file"
                                            
                                            />
                                    </FormControl>
                                    <FormControl id="name" float="right">
                                        <Button
                                            mt={2}
                                            colorScheme="blue"
                                            type="submit"
                                            variant="solid"
                                            bg="#0D74FF"
                                            color="white">
                                              {t(`send`)}
                                          </Button>
                                          {file && (
                                              <Text mt={2} color={maxFileSize ? "red.500" : "green.500"} fontWeight="bold">
                                                  {
                                                      maxFileSize ? 'File size should be less than 500KB' : `File selected successfully`
                                                  }
                                              </Text>
                                          )}
                                    </FormControl>
                                </form>
                            </VStack>
                        </Box>
                        </Box>
                    </WrapItem>
                    </Wrap>
                </Box>
                </Box>
            </Flex>
        </Container>
    )
}
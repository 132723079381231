import React from 'react';
import { createIcon } from "@chakra-ui/react";


const Dxf = createIcon({
    viewBox: '0 0 1000 1000',
    displayName: 'Dxf',
    path: [
        <svg version="1.1"  viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
        <g><g><g><g>
            <path d="M300,753.1c-11,0-18.1,1-22.3,2v142.3c4.2,1,11,1,17.1,1c44.5,0.3,73.6-24.2,73.6-76.2C368.7,777,342.3,753.1,300,753.1z" fill='currentColor' />
            <path d="M855.3,244.9c-0.1-4.5-1.5-8.9-4.6-12.4l-189-215.8c0-0.1-0.1-0.1-0.1-0.1c-1.1-1.3-2.4-2.3-3.8-3.2c-0.4-0.3-0.8-0.5-1.3-0.8c-1.2-0.7-2.5-1.2-3.8-1.6c-0.4-0.1-0.7-0.3-1-0.3c-1.4-0.3-2.9-0.5-4.4-0.5H182.9c-21.2,0-38.4,17.2-38.4,38.4v903.1c0,21.2,17.2,38.4,38.4,38.4h634.1c21.2,0,38.4-17.2,38.4-38.4V247C855.5,246.3,855.4,245.6,855.3,244.9z M384.6,908.7c-22.9,19.1-57.8,28.1-100.4,28.1c-25.5,0-43.6-1.6-55.8-3.2V719.9c18.1-2.9,41.6-4.5,66.5-4.5c41.3,0,68.1,7.4,89.1,23.3c22.6,16.8,36.8,43.6,36.8,82C420.7,862.2,405.5,890.9,384.6,908.7z M556.9,934.5l-19.7-39.4c-8.1-15.2-13.2-26.5-19.4-39h-0.6c-4.5,12.6-10,23.9-16.8,39l-18.1,39.4h-56.2l62.9-110L428.4,717h56.5l19,39.7c6.5,13.2,11.3,23.9,16.5,36.1h0.6c5.2-13.8,9.4-23.5,14.8-36.1l18.4-39.7h56.2l-61.3,106.2l64.5,111.4H556.9L556.9,934.5z M773.1,757.3h-83.6v49.7h78.1v40h-78.1v87.5h-49.4V717h133L773.1,757.3L773.1,757.3L773.1,757.3z M182.9,662.5V48.4h445.2v196.6c0,10.6,8.6,19.2,19.2,19.2h169.7l0,398.3H182.9z" fill='currentColor'/></g>
            <path d="M716,573.4c-24.7-58.7-49.5-117.3-74.3-176c-13.6-32.2-27.3-64.3-40.9-96.5c-14.8-35-29.6-70.1-44.2-105.2c-3.2-7.7-8-13-16.5-14.3c-0.4-0.1-126.3,0.5-126.6,0.5c-8-0.1-12.5,4.5-15.4,11.3c-4,9.3-7.8,18.6-11.7,27.9c-14.5,34.6-29,69.2-43.4,103.8c-18.1,43.2-36.1,86.5-54.2,129.7c-1.6,3.8-3.4,7.6-4.8,11.5c-0.7,2.1-1.2,4.1-1.4,6l-0.3-0.3c0,0,0,0.7,0.1,1.9c-0.2,2.8-0.1,5.5,0.5,7.9l29.9,83.1c9,29.2,22.6,19.9,38,13.5c15.5-6.3,185.5-78.8,185.5-78.8l-223-2.3l80.1-6.4L469,309l-33.6-107l44.4,95.1c10.2,24.3,20.4,48.6,30.6,72.8c18.4,43.5,36.9,87,55.2,130.6c15.3,36.2,16.6,39.4,31.8,75.5c39.8,0,79.6,0,119.4,0C712,564.2,721,585.3,716,573.4z" fill='currentColor'/>
        </g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></g>
        </svg>
    ]
})

export default Dxf;
import React from "react";
import { Box, ButtonGroup, Card, CardBody, CardFooter, Heading, Icon, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import ArrowDown from "../../theme/icons/ArrowDown";

export default function FirePlaceCard({card, seeMore, sizeW, size, ...rest}) {
    return (
        <Card {...rest}
          mx={2}
          boxShadow={{base: 'none', md: 'dark-lg'}}
          bgColor="#1F1F1F"
          maxW={size}
          w={sizeW}
          borderRadius="xl"
          color="white"
          px={4}
          py={6}
          transition={'all .5s ease'}
        >
        <CardBody>
          <Heading align="center" size='xl'>{card?.title}</Heading>
          <Image
            h={{base: '200px', md: '310px',}}
            margin="0 auto"
            src={card?.imgUrl}
            alt='fireplace'
          />
        </CardBody>
        <CardFooter>
          <ButtonGroup
            className="see-more"
            transition="all .5s ease"
            display="flex"
            alignItems="center"
            width="50px"
            overflow="hidden"
            spacing='2'>
              <Box
                borderColor="orange.300"
                borderWidth="1px"
                p="10px 13px"
                borderRadius="3xl"
              >
                <Link
                  to={`products/${card.title}`}>
                  <Icon
                    fontSize="19px"
                    transform="rotate(-90deg)"
                    color="white" as={ArrowDown} />
                </Link>
              </Box>
              <Box
                minW="150px"
              >
                {seeMore}
              </Box>
          </ButtonGroup>
        </CardFooter>
      </Card>
    );
}

FirePlaceCard.defaultProps = {
    size: 'sm'
    
}
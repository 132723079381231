import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react'
import Header from '../components/Header';
import TextImageBlock from '../components/TextImageBlock';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import Hero from '../components/Hero';
import video from '../assets/IMG_2191.mp4'
import { useTranslation } from 'react-i18next';
import company from '../assets/companyy.jpg'
import services from '../assets/services.jpeg'
// import products from '../assets/products.png'
// import er2 from '../assets/er2.png'
// import er3 from '../assets/er3.png'
import er4 from '../assets/er4.png'

const About = () => {
    const {id} = useParams();
    const {t, i18n} = useTranslation('common');

    const scrollToDown = () => {
        window.scrollTo({
            top: 700,
            behavior: "smooth",
          });
    }
    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, [id]);
    useEffect(() => {
        scrollToDown()
    })
    return (
        <>
            <Box position="relative">
                <Box position="relative">
                <Box
                    as="video"
                    objectFit='cover'
                    height={{base: '650px', sm: '650px', md: '800px', xl: '100vh'}}
                    loop
                    muted
                    width="100%"
                    playsInline
                    webkitPlaysinline
                    autoPlay src={video}/>
                <Box position="absolute" width="100%" bgColor='rgba(255,255,255,.1)' height={{base: '650px', sm: '650px', md: '800px', xl: '100vh'}} top="0">
                    <Header t={t} i18n={i18n} />
                    <Hero scrollTo={scrollToDown} title={t('pagesTitle.about')} />
                </Box>
            </Box>
                <Flex
                    direction="column"
                    margin={{base: "100px auto", md: "100px auto"}}
                    maxW={{base: "unset", md: "1600px"}}
                >
                    <TextImageBlock
                        withoutSeeMore
                        title={t(`aboutpage.about.title`)}
                        description={t(`aboutpage.about.description`)}
                        imageUrl={company}
                        imageOrder={{base: "2", md: "1"}}
                        textOrder="2"
                        marginY="0px"/>
                    <TextImageBlock
                        withoutSeeMore
                        title={t(`aboutpage.product.title`)}
                        description={t(`aboutpage.product.description`)}
                        imageOrder={{base: "1", md: "2"}}
                        imageUrl={er4}
                        textOrder="1"
                        marginY="100px"/>
                    <TextImageBlock
                        withoutSeeMore
                        title={t(`aboutpage.service.title`)}
                        description={t(`aboutpage.service.description`)}
                        imageUrl={services}
                        imageOrder={{base: "2", md: "1"}}
                        textOrder="2"
                        marginY="0px"/>
                </Flex>
            </Box>
            <Footer t={t} />
        </>
    )
}

export default About;
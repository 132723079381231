import React from 'react'
import SimpleSidebar from '../Navbar';

const Header = ({bgColor, t, i18n, position}) => {
    return (
        <>
            <SimpleSidebar t={t} i18n={i18n} position={position} bgColor={bgColor}/>
        </>
    )
}

export default Header;